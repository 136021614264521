import React from 'react'
import { useSelector } from 'react-redux'
import { ActivityIndicator } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  CoachProfile,
  HealthDataMeasurementType,
  UserGoalKind,
  UserGoalMetricKind,
  UserInsurancePolicyStateKind,
} from '@src/types'
import { goalMetricsSelector, primaryGoalSelector } from '@src/selectors/app'
import {
  appointmentRecurrenceSelector,
  insurancePolicySelector,
  topPriorityCoachAssignmentSelector,
} from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { ImageStyle } from '@src/components/LoadingFastImage'
import { Feature, TouchableSection, useFeatureFlag } from '@src/components'
import { useIsDarkMode } from '@src/config/theme'
import { hasUnlimitedCalls } from '@src/screens/NutritionistHub/utils'
import { RECOMMENDED_WEIGHT_LOSS_RATE } from '@src/screens/NutritionistHub/components/SetWeightLossGoalScreen'
import { useUnitSystemMeasurements } from '@src/screens/Measurements/hooks'

export const LossWeighRecurrentCallsPopup = () => {
  const navigation = useNavigation()
  const flagEnabled = useFeatureFlag(Feature.WeightLossRecurrentCalls)
  const primaryGoal = useSelector(primaryGoalSelector)
  const goalMetrics = useSelector(goalMetricsSelector)
  const measurementUnitConfig = useUnitSystemMeasurements()
  const { units } = measurementUnitConfig[HealthDataMeasurementType.Weight]

  const videoCallRecurrence = useSelector(appointmentRecurrenceSelector)

  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const nutritionist = coachAssignment?.coach
  const { photo } = nutritionist?.coachProfile || ({} as CoachProfile)
  const insurancePolicy = useSelector(insurancePolicySelector)

  const styles = useStyleSheet(themedStyles)
  const isDarkMode = useIsDarkMode()

  const goalIsWeightLoss = primaryGoal?.kind === UserGoalKind.LoseWeight
  const insurancePolicyIsEligible =
    insurancePolicy?.lastState?.kind === UserInsurancePolicyStateKind.Eligible &&
    hasUnlimitedCalls(insurancePolicy)
  const userIsEligible = goalIsWeightLoss && insurancePolicyIsEligible && !videoCallRecurrence

  const showPopup = flagEnabled && userIsEligible

  if (!showPopup) {
    return null
  }

  const weightLossGoalMetric = goalMetrics?.find(
    (metric) => metric.kind === UserGoalMetricKind.MeasurementsWeight,
  )

  const onPress = () => {
    if (
      weightLossGoalMetric &&
      weightLossGoalMetric.highThresholdValue &&
      weightLossGoalMetric.lowThresholdValue
    ) {
      return navigation.navigate('ConfirmWeightLossRecurrence', {
        weightLossRate: RECOMMENDED_WEIGHT_LOSS_RATE[units as 'pounds' | 'kilos'],
        currentWeight: weightLossGoalMetric.highThresholdValue,
      })
    }
    return navigation.navigate('SetWeightLossGoal')
  }

  return (
    <TouchableSection
      title="Hit your weight loss goal!"
      subtitle="Schedule frequent calls with your nutritionist and start seeing results!"
      onPress={onPress}
      style={[styles.content, isDarkMode && styles.darkContent]}
      subtitleStyle={styles.subtitle}
      labelStyle={styles.title}
      accessoryLeft={
        photo && (
          <ImageWithPlaceholder
            style={styles.nutritionistPhoto as ImageStyle}
            source={{ uri: photo }}
            placeholderComponent={<ActivityIndicator animating size="small" />}
          />
        )
      }
      showRightArrow
    />
  )
}

const themedStyles = StyleService.create({
  title: {
    color: 'theme.text.primary',
  },
  subtitle: {
    color: 'theme.text.secondary',
    maxWidth: 246,
  },
  content: {
    marginHorizontal: 16,
    marginTop: 16,
    marginBottom: 8,
    height: 'auto',
    backgroundColor: 'theme.warning.lightest',
    borderRadius: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
  },
  darkContent: {
    backgroundColor: 'theme.background.modal',
  },
  nutritionistPhoto: {
    width: 48,
    height: 48,
    borderRadius: 24,
    marginRight: 16,
  },
})
