import {
  AppointmentType,
  InsuranceBerryStreetAppointmentChargeType,
  InsuranceBerryStreetAppointmentMeetingStatus,
  InsuranceBerryStreetAppointmentStatus,
  InsuranceBerryStreetAppointmentType,
  InsurancePolicy,
  LatestUserAppointment,
  UserInsurancePolicyStateKind,
} from '@src/types'

import { DEFAULT_VIDEO_CALL_INFO } from './constants'

export interface VideoCallDetails extends LatestUserAppointment {
  id: string
  title: string
  date: string | null
  meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
  appointmentType: InsuranceBerryStreetAppointmentType
  isSchedulingPending: boolean
  isCallConfirmed: boolean
  isCallInReview: boolean
  isCallCancelled: boolean
  isCallOccurred: boolean
  isInsuranceCallCancelled: boolean
  nutritionistName?: string | null
  addToGoogleCalendarLink?: string | null
  unauthenticatedIcsLink?: string | null
  chargeType?: InsuranceBerryStreetAppointmentChargeType | null
}

export const isCallConfirmed = (
  appointmentStatus: InsuranceBerryStreetAppointmentStatus,
): boolean => appointmentStatus === InsuranceBerryStreetAppointmentStatus.ReviewCompleted

export const getVideoCallDetails = (appointment: LatestUserAppointment): VideoCallDetails => {
  return {
    ...appointment,
    date: appointment.date || null,
    appointmentType: appointment.appointmentType || InsuranceBerryStreetAppointmentType.Initial_60,
    title: appointment.title || DEFAULT_VIDEO_CALL_INFO.title,
    isSchedulingPending:
      appointment.status === InsuranceBerryStreetAppointmentStatus.SchedulingPending,
    isCallConfirmed: isCallConfirmed(appointment.status),
    isCallInReview: appointment.status === InsuranceBerryStreetAppointmentStatus.InReview,
    isCallCancelled:
      appointment.meetingStatus === InsuranceBerryStreetAppointmentMeetingStatus.Cancelled,
    isCallOccurred:
      appointment.meetingStatus === InsuranceBerryStreetAppointmentMeetingStatus.Occurred,
    isInsuranceCallCancelled:
      appointment.meetingStatus === InsuranceBerryStreetAppointmentMeetingStatus.Cancelled &&
      appointment.appointmentType !== InsuranceBerryStreetAppointmentType.NoneCovered,
  }
}

export const isInsuranceEligible = (insurancePolicy: InsurancePolicy | null): boolean => {
  if (!insurancePolicy || !insurancePolicy.lastState) {
    return false
  }

  return [
    UserInsurancePolicyStateKind.Eligible,
    UserInsurancePolicyStateKind.EligiblePatientResponsibility,
    UserInsurancePolicyStateKind.EligiblePreAuth,
  ].includes(insurancePolicy.lastState.kind)
}

export const insuranceSubmittedWithoutCall = (
  insurancePolicy: InsurancePolicy | null,
  videoCallDetails: VideoCallDetails,
): boolean => {
  if (!insurancePolicy || videoCallDetails.id || !insurancePolicy.lastState) {
    return false
  }

  return [UserInsurancePolicyStateKind.Created, UserInsurancePolicyStateKind.Hold].includes(
    insurancePolicy.lastState.kind,
  )
}

export const isFollowupSchedulingAvailable = (
  insurancePolicy: InsurancePolicy | null,
  videoCallDetails: VideoCallDetails,
): boolean => {
  if (!insurancePolicy || isCallScheduled(videoCallDetails)) {
    return false
  }

  if (
    insurancePolicy.initialAppointmentType?.key === videoCallDetails.appointmentType &&
    videoCallDetails.isCallCancelled
  ) {
    // user canceled initial call, followup call is not available
    return false
  }

  if (insurancePolicy.unitsUsed === 0) {
    return false
  }

  return hasUnusedCalls(insurancePolicy)
}

export const hasUnusedCalls = (insurancePolicy: InsurancePolicy | null): boolean => {
  if (!insurancePolicy || !isInsuranceEligible(insurancePolicy)) {
    return false
  }

  const unitsLeft = insurancePolicy.unitsLeft ?? 0

  // -1 means unlimited
  return unitsLeft > 0 || unitsLeft === -1
}

export const hasUnlimitedCalls = (insurancePolicy: InsurancePolicy | null): boolean => {
  const unitsLeft = insurancePolicy?.unitsLeft ?? 0
  return unitsLeft === -1
}

export const isUnusedCallsInfoAvailable = (insurancePolicy: InsurancePolicy | null): boolean => {
  if (!insurancePolicy) {
    return false
  }

  return insurancePolicy.unitsLeft !== null && insurancePolicy.unitsLeft !== undefined
}

export const isInsuranceDeclined = (insurancePolicy: InsurancePolicy | null): boolean => {
  if (!insurancePolicy || !insurancePolicy.lastState) {
    return false
  }

  return [
    UserInsurancePolicyStateKind.NotSupported,
    UserInsurancePolicyStateKind.NotEligible,
    UserInsurancePolicyStateKind.Canceled,
  ].includes(insurancePolicy.lastState.kind)
}

export const isInsuranceReviewInProgress = (insurancePolicy: InsurancePolicy | null): boolean => {
  if (!insurancePolicy || !insurancePolicy.lastState) {
    return false
  }

  return [
    UserInsurancePolicyStateKind.Created,
    UserInsurancePolicyStateKind.Hold,
    UserInsurancePolicyStateKind.InReview,
  ].includes(insurancePolicy.lastState.kind)
}

export const isBookingAvailable = (
  insurancePolicy: InsurancePolicy | null,
  videoCallDetails: VideoCallDetails,
) => {
  return (
    hasUnusedCalls(insurancePolicy) ||
    videoCallDetails.isSchedulingPending ||
    insuranceSubmittedWithoutCall(insurancePolicy, videoCallDetails)
  )
}

// when meeting status is present, it means the call is either occurred or
// won't happen(cancelled, no_show or rescheduled)
export const isCallScheduled = (videoCallDetails: VideoCallDetails): boolean =>
  (videoCallDetails.isCallConfirmed || videoCallDetails.isCallInReview) &&
  !videoCallDetails.meetingStatus

const appointmentTypeTitle = (
  appointmentTypes: AppointmentType[],
  appointmentType?: InsuranceBerryStreetAppointmentType,
) => {
  const type = appointmentTypes.find((type) => type.key === appointmentType)
  return type?.title || DEFAULT_VIDEO_CALL_INFO.title
}

export const getSchedulingConfig = (
  videoCallDetails: VideoCallDetails,
  followupCallNotScheduled: boolean,
  initialAppointmentType: InsuranceBerryStreetAppointmentType | undefined,
  appointmentTypes: AppointmentType[],
) => {
  if (videoCallDetails.isSchedulingPending) {
    return {
      navigationParams: {
        appointmentType: videoCallDetails.appointmentType,
        pendingAppointmentId: videoCallDetails.id,
        allowBackNavigation: true,
      },
      title: videoCallDetails.title,
    }
  }

  if (followupCallNotScheduled) {
    return {
      navigationParams: {
        appointmentType: InsuranceBerryStreetAppointmentType.Followup,
        allowBackNavigation: true,
      },
      title: appointmentTypeTitle(appointmentTypes, InsuranceBerryStreetAppointmentType.Followup),
    }
  }

  if (videoCallDetails.isInsuranceCallCancelled) {
    return {
      navigationParams: {
        appointmentType: videoCallDetails.appointmentType,
        allowBackNavigation: true,
      },
      title: videoCallDetails.title,
    }
  }

  return {
    navigationParams: {
      appointmentType: initialAppointmentType || InsuranceBerryStreetAppointmentType.Initial_60,
      allowBackNavigation: true,
    },
    title: appointmentTypeTitle(appointmentTypes, initialAppointmentType),
  }
}
