import React from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, IconButton, Pill, Text } from '@src/components/base'
import { InsuranceBerryStreetAppointmentType } from '@src/types'
import {
  VIDEO_CALL_CARD_DATE_FORMAT,
  VIDEO_CALL_CARD_DATE_WITH_TIME_FORMAT,
  VIDEO_CALL_CARD_TIME_FORMAT,
} from '@src/screens/NutritionistHub/constants'
import { useVideoCallDurations } from '@screens/NutritionistHub/hooks/useVideoCallDurations.ts'

export interface SuggestedCallCardProps {
  title?: string | null
  meetingAt: string
  nutritionistName?: string | null
  appointmentType: InsuranceBerryStreetAppointmentType
  pillText: string
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  schedulingDisabled?: boolean
  onSchedulePress: () => void
  onOpenSchedulerPress?: () => void
}

export const SuggestedCallCard = ({
  title,
  meetingAt,
  nutritionistName,
  appointmentType,
  pillText,
  style,
  textStyle,
  onOpenSchedulerPress,
  onSchedulePress,
  schedulingDisabled,
}: SuggestedCallCardProps) => {
  const styles = useStyleSheet(themedStyles)
  const { getVideoCallDuration } = useVideoCallDurations()

  return (
    <View style={[styles.container, style]}>
      <View>
        <Pill text={pillText} status="info" style={styles.pill} />
      </View>
      {!nutritionistName ? (
        <View style={styles.row}>
          <View style={styles.textContainer}>
            <Text type="regular" style={[styles.time, textStyle]}>
              {moment(meetingAt).format(VIDEO_CALL_CARD_DATE_FORMAT)}
            </Text>
            <Text type="regular" style={styles.timeSecondary}>
              {moment(meetingAt).format(VIDEO_CALL_CARD_TIME_FORMAT)} -{' '}
              {moment(meetingAt)
                .add(getVideoCallDuration(appointmentType), 'minutes')
                .format(VIDEO_CALL_CARD_TIME_FORMAT)}
            </Text>
          </View>
          <Button
            accessibilityLabel="Book Suggested Call"
            type="primary"
            size="small"
            onPress={onSchedulePress}
            disabled={schedulingDisabled}
          >
            Schedule
          </Button>
        </View>
      ) : (
        <>
          <View style={styles.titleRow}>
            <Text type="regular" bold style={styles.titleText}>
              {title || ''} with {nutritionistName.split(' ')[0] || ''}
            </Text>
            <View style={styles.titleButtons}>
              {onOpenSchedulerPress && (
                <IconButton
                  iconName="pencil-simple"
                  size="m"
                  type="outline"
                  accessibilityLabel="Open Scheduler"
                  onPress={onOpenSchedulerPress}
                />
              )}
              <Button
                accessibilityLabel="Book Suggested Call"
                type="primary"
                size="small"
                onPress={onSchedulePress}
                disabled={schedulingDisabled}
              >
                Schedule
              </Button>
            </View>
          </View>
          <View style={styles.divider} />
          <Text type="small" style={[styles.time, textStyle]}>
            {moment(meetingAt).format(VIDEO_CALL_CARD_DATE_WITH_TIME_FORMAT)} -{' '}
            {moment(meetingAt)
              .add(getVideoCallDuration(appointmentType), 'minutes')
              .format(VIDEO_CALL_CARD_TIME_FORMAT)}
          </Text>
        </>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    borderLeftWidth: 3,
    borderColor: 'theme.info.light_',
    paddingLeft: 12,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    marginTop: 16,
  },
  textContainer: {
    flex: 1,
  },
  titleRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: 'theme.surface.base1',
  },
  titleText: {
    color: 'theme.text.tertiary',
    maxWidth: '60%',
  },
  titleButtons: {
    flexDirection: 'row',
    gap: 8,
  },
  time: {
    color: 'theme.text.primary',
  },
  timeSecondary: {
    color: 'theme.text.secondary',
    marginTop: 4,
  },
  pill: {
    alignSelf: 'flex-start',
  },
})
