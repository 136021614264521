export enum Feature {
  AbbottPartnership = 'abbott_partnership',
  AllCourseModulesUnlocked = 'all_course_modules_unlocked',
  AllowExpiredSensorScan = 'allow_expired_sensor_scan',
  AllowSkipPurchaseFlow = 'allow_skip_purchase_flow',
  ByosOnboarding = 'byos_onboarding',
  ByosOnboardingHomepage = 'byos_onboarding_homepage',
  ByosFreeTrialUpdate = 'byos_free_trial_update',
  Chatbot = 'chatbot',
  CustomHeroChart = 'custom_hero_chart',
  DailyActionsHomeWalkthrough = 'daily_actions_home_walkthrough',
  DebugBluetoothSensorScanning = 'debug_bluetooth_sensor_scanning',
  DebugNfcSensorScanning = 'debug_nfc_sensor_scanning',
  DebugNfcSensorScanningError = 'debug_nfc_sensor_scanning_error',
  DexcomIntegration = 'dexcom_integration',
  DexcomReadyToParticipate = 'dexcom_ready_to_participate',
  DexcomResearch = 'dexcom_research',
  DexcomUpgradeBanner = 'dexcom_upgrade_banner',
  DisableLibre3OptIn = 'disable_libre3_opt_in',
  ForceRegisterNotificationToken = 'force_register_notification_token',
  GlucoseCheckReminder = 'glucose_check_reminders_mobile',
  HomeScreenDialChart = 'home_screen_dial_chart',
  IncludeCurrentDayInHealthDataSubmission = 'include_current_day_in_health_data_submission',
  InsightsTooltip = 'insights_tooltip',
  InsuranceCoveredVideoConsult = 'insurance_covered_video_consult',
  InsuranceResubmission = 'insurance_resubmission',
  Libre2Support = 'libre2_support',
  Libre3DisconnectOnSensorRemoval = 'libre3_sensor_disconnect',
  Libre3RealTime = 'libre_3_real_time',
  Libre3RealTimeGlucoseChart = 'libre_3_real_time_glucose_chart',
  Libre3ScanningWithoutLibreLinkUpPatientId = 'libre3_scanning_without_libre_link_up_patient_id',
  Libre3Default = 'libre_3_as_default',
  LibrelinkupConnection = 'librelinkup_connection',
  MealExperimentation = 'meal_experimentation',
  MedicalDisclaimerSurvey = 'medical_disclaimer_survey',
  MemberNotes = 'member_notes',
  MobileAppPresentationMode = 'mobile_app_presentation_mode',
  NutritionistMenuMostPopularTag = 'nutritionist_menu_most_popular_tag',
  NutritionistMenuProductsSequence = 'nutritionist_menu_products_sequence',
  OnboardingChecklist = 'onboarding_checklist',
  OutcomesTracking = 'outcomes_tracking',
  OwnSensorOnboardingFlow = 'own_sensor_onboarding_flow',
  SaveCgmLogsToBetterStack = 'save_cgm_logs_to_better_stack',
  SaveCgmLogsToSegment = 'save_cgm_logs_to_segment',
  ScanDisableAnnouncements = 'scan_disable_announcements',
  ScanDisableFullLibre1And2 = 'scan_disable_full_libre_1_and_2',
  ScanDisableNoticeOnScan = 'scan_disable_notice_on_scan',
  SeparateEnrollment = 'research_prescription_separate_enrollment_mobile',
  SkipSensorSelection = 'skip_sensor_selection_returning_user',
  StartDexcomParticipation = 'start_dexcom_participation',
  SubscriptionChangeBillingCycle = 'subscription_change_billing_cycle',
  SubscriptionPausing = 'subscription_pausing',
  TerraKetoMojo = 'terra_ketomojo',
  UkSupport = 'uk_support',
  UserDailyActions = 'user_daily_actions',
  UserGoals = 'user_goals',
  VideoCallFees = 'video_call_fees',
  VideoCallFunnelOptimization = 'video_call_funnel_optimization',
  VideoCallRetention = 'video_call_retention',
  VideoCallsEarlierAvailableSlotsCheck = 'video_calls_earlier_available_slots_check',
  InsuranceCardSubmission = 'insurance_card_submission',
  ConsumerHealthPrivacyPolicy = 'consumer_health_privacy_policy',
  SwitchToPrescriptionDexcom = 'switch_to_prescription_dexcom',
  WeightLossRecurrentCalls = 'weigh_loss_recurrent_calls',
}

export interface FeatureFlagProps {
  feature: Feature
  children: any
  fallbackComponent?: React.ReactNode
}
